import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  AddProduct,
  basketReset,
  UpdateSantaLetterCardForProduct,
} from '../../../store/Slices/gifts/basketSlice';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../utils/behaviorSettings';
import useScreenSize from '../../../hooks/useScreenSize';
import Button from '../../Button';
import SantaLetterForm from './SantaLetterForm';
import SantaLetterTemplate from './SantaLetterTemplate';
import EditIcon from '../../../public/static/icons/edit.svg';
import CloseIcon from '../../../public/static/icons/close-rounded.svg';
import LoaderIcon from '../../../public/static/icons/loader.svg';
import ZoomInIcon from '../../../public/static/icons/zoom-in.svg';
import Popup from '../../Popup';
import SantaLetterModal from './SantaLetterModal/SantaLetterModal';
import { propTypes as linkPropTypes } from '../../Link';
import { basketBannerHide } from '../../../store/Slices/gifts/basketBannerSlice';
// We have to use the original component instead of /components/01_atoms/Tooltip
// because of complex behaviour;
import { Tooltip } from 'react-tooltip';

import s from './index.module.scss';
import ps from '../../../componentsGifts/Popup/index.module.scss';
import ts from '../../../components/01_atoms/Tooltip/index.module.scss';

const BBSantaLetter = ({ title, productId, modal, behaviorSettings, uuid }) => {
  const dispatch = useDispatch();
  const { width, height } = useScreenSize();
  const basketType = useSelector((state) => state.basket.type);
  const isDesktop = width >= 1200;
  const [letterWrapperWidth, setLetterWrapperWidth] = useState(0);
  const [letterPreviewWrapperWidth, setLetterPreviewWrapperWidth] = useState(0);
  const [isTouched, setIsTouched] = useState(false);
  const [isTouchedModal, setIsTouchedModal] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [closeTooltipOpen, setCloseTooltipOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const formStateInitialValues = {
    name: '',
    present: '',
    post_scriptum: '',
  };
  const [formState, setFormState] = useState(formStateInitialValues);
  const products = useSelector((state) => state.productsStorage.gifts);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeField, setActiveField] = useState(null);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 1000);
  }, []);

  const letterWrapper = useRef(null);
  const letterPreviewWrapper = useRef(null);
  useEffect(() => {
    if (letterWrapper.current) {
      setLetterWrapperWidth(letterWrapper.current.offsetWidth);
    }
    if (letterPreviewWrapper.current) {
      setLetterPreviewWrapperWidth(letterPreviewWrapper.current.offsetWidth);
    }
  }, [width, height]);

  useEffect(() => {
    // The ref is inside the popup and needs some time to render it.
    setTimeout(() => {
      if (letterPreviewWrapper.current) {
        setLetterPreviewWrapperWidth(letterPreviewWrapper.current.offsetWidth);
      }
    }, 100);
  }, [previewOpen]);

  const isFilled = ['name', 'present', 'post_scriptum'].every((field) => !!formState[field]);

  // The actual submit handler that adds product to basket.
  const onSubmit = (e, reset) => {
    const product = products[productId];

    if (product) {
      if (basketType && basketType !== 'gift') {
        // Clear basket in case it has Corporate gifts. Edge case.
        dispatch(basketReset());
      }
      dispatch(AddProduct(product));
      dispatch(
        UpdateSantaLetterCardForProduct({
          productId: productId,
          card: formState,
        }),
      );

      // We need to remove focus state from Submit button to avoid
      // submission process when we click 'Create another letter' button buy keyboard Enter key.
      const title = document.getElementById('santa-letter-heading');
      if (title) {
        title.tabIndex = 0;
        title.focus();
      }
      setSubmitModalVisible(true);
      dispatch(basketBannerHide());
    }
  };

  // Values updater used for modal form.
  const onSubmitModal = (values) => {
    setFormState({
      name: values.name_modal,
      present: values.present_modal,
      post_scriptum: values.post_scriptum_modal,
    });
    if (!isTouched) {
      setIsTouched(true);
    }
    setOverlayOpen(false);
    setIsAnimating(true);
    setIsTouchedModal(false);
    setTimeout(() => {
      setIsAnimating(false);
    }, 500);
  };

  // Values updater used for desktop form.
  const onChange = (values) => {
    setFormState(values);
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const onChangeModal = () => {
    if (!isTouchedModal) {
      setIsTouchedModal(true);
    }
  };

  const onPopupClose = () => {
    setCloseTooltipOpen(false);
    setOverlayOpen(false);
    setIsTouchedModal(false);
  };

  // Close button tooltip action handlers.
  const onPopupCloseAttempt = () => {
    if (isTouchedModal) {
      setCloseTooltipOpen(true);
    } else {
      onPopupClose();
    }
  };

  const handleMouseEnter = () => {
    if (!isDesktop) {
      return;
    }

    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseMove = (e) => {
    if (!isDesktop) {
      return;
    }

    const { left, top, width, height } = letterWrapper.current.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;

    // Calculate the percentage position within the letter wrapper
    const xPercent = (x / width) * 100;
    const yPercent = (y / height) * 100;

    setMousePosition({ x: xPercent, y: yPercent });
  };

  // Custom close button that shows a tooltip.
  // Not a standard way of using tooltip, but only this way desired behaviour can be achieved.
  const closeButtonElement = (
    <div className={s['bb-santa-letter__modal-close']}>
      <button className={ps['close']} onClick={onPopupCloseAttempt} id="santa-letter-modal-close">
        <CloseIcon aria-label="Close overlay" />
      </button>
      <div className={`${s['bb-santa-letter__modal-close-tooltip']} ${ts['tooltip']}`}>
        <Tooltip anchorSelect="#santa-letter-modal-close" isOpen={closeTooltipOpen} clickable>
          <div>If you close this window you will have to start again.</div>
          <div className={s['bb-santa-letter__modal-close-tooltip-actions']}>
            <Button type="secondary" size="small" onClick={onPopupClose}>
              Close
            </Button>
            <Button type="neutral" size="small" onClick={() => setCloseTooltipOpen(false)}>
              Cancel
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );

  const previewCloseButtonElement = (
    <div className={s['bb-santa-letter__preview-modal-close']}>
      <Button type="light" size="large" onClick={() => setPreviewOpen(false)}>
        Close
      </Button>
    </div>
  );

  const handleFocusChange = (fieldName) => {
    setActiveField(fieldName);
  };

  // The formula is "letter wrapper width" / (2515 / 2).
  const scaleFactor = letterWrapperWidth / 1257;
  const previewScaleFactor = letterPreviewWrapperWidth / 1257;

  const classes = [s['bb-santa-letter'], generateClassNameByBehaviorSettings(behaviorSettings)];
  const letterWrapperClasses = [
    s['bb-santa-letter__letter-wrapper'],
    ...[isAnimating ? s['bb-santa-letter__letter-wrapper--updating'] : []],
    ...[isHovered ? s['bb-santa-letter__letter-wrapper--letter-zoomed'] : []],
  ];
  const letterClasses = [
    s['bb-santa-letter__letter'],
    ...[isLoaded ? s['bb-santa-letter__letter--initialized'] : []],
    ...[isHovered ? s['bb-santa-letter__letter--zoomed'] : []],
    ...[activeField ? s[`bb-santa-letter__letter--zoomed-${activeField}`] : []],
    ...[!isFilled ? s[`bb-santa-letter__letter--not-filled`] : []],
  ];

  return (
    <div className={classes.join(' ')} id={uuid}>
      <link
        href="https://fonts.googleapis.com/css2?family=Atma:wght@500&display=block"
        rel="stylesheet"
      />
      <div className={`container ${s['bb-santa-letter__container']}`}>
        <div className={s['bb-santa-letter__content']}>
          {isDesktop && (
            <div className={`${s['bb-santa-letter__zoom-info']} ${s['bb-santa-letter__zoom-info--desktop']}`}>
              <ZoomInIcon />
              <span>Mouse over to zoom</span>
            </div>
          )}
          <h1 className={s['bb-santa-letter__heading']} id="santa-letter-heading">
            {title}
          </h1>
          <div
            className={letterWrapperClasses.join(' ')}
            ref={letterWrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
          >
            <LoaderIcon className={s['bb-santa-letter__loader']} />
            <div
              className={letterClasses.join(' ')}
              style={{
                transform: `scale(${scaleFactor})`,
                // Original formulas which fits the latter to the exact border of the wrapper:
                // left: `${mousePosition.x * -0.5 - 25}%`,
                // top: `${mousePosition.y * -0.5 - 25}%`,
                // Adjusted formulas which allow zoomed area to go outside the letter by 5%, which feels more airy:
                left: isDesktop ? `${mousePosition.x * -0.6 - 20}%` : 0,
                top: isDesktop ? `${mousePosition.y * -0.6 - 20}%` : 0,
              }}
              onClick={isDesktop ? () => {} : () => setPreviewOpen(true)}
              tabIndex={isDesktop ? -1 : 0}
            >
              <SantaLetterTemplate
                name={formState.name}
                present={formState.present}
                postScriptum={formState.post_scriptum}
              />
            </div>
          </div>
          {!isDesktop && (
            <>
              <div className={s['bb-santa-letter__zoom-info']}>
                <ZoomInIcon />
                <span>Tap on the image to zoom</span>
              </div>
              <div
                className={`${s['bb-santa-letter__actions']} ${previewOpen ? s['bb-santa-letter__actions--no-sticky'] : ''}`}
              >
                <div className={s['bb-santa-letter__customize']}>
                  <Button
                    type={isTouched ? 'neutral' : 'xmas'}
                    size="large"
                    onClick={() => setOverlayOpen(true)}
                  >
                    <EditIcon />
                    {isTouched ? 'Edit' : 'Customize'}
                  </Button>
                </div>
                {isFilled && (
                  <div className={s['bb-santa-letter__submit']}>
                    <Button type="xmas" size="large" onClick={onSubmit}>
                      Add to basket
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}

          {isDesktop && (
            <div className={s['bb-santa-letter__form-desktop']}>
              <SantaLetterForm
                onSubmit={onSubmit}
                initialValues={formState}
                onChange={onChange}
                onFocusChange={handleFocusChange}
              />
            </div>
          )}
        </div>
      </div>

      {!isDesktop && overlayOpen && (
        <Popup
          onRequestClose={onPopupCloseAttempt}
          isOpen={true}
          className={s['bb-santa-letter__modal']}
          overlayClassName={s['bb-santa-letter__overlay']}
          closeButton={closeButtonElement}
        >
          <div className={s['bb-santa-letter__form-mobile']}>
            <SantaLetterForm
              onSubmit={onSubmitModal}
              initialValues={{
                name_modal: formState.name,
                present_modal: formState.present,
                post_scriptum_modal: formState.post_scriptum,
              }}
              onChange={onChangeModal}
              isModal
              isFilled={isFilled}
            />
          </div>
        </Popup>
      )}

      {!isDesktop && previewOpen && (
        <Popup
          onRequestClose={() => setPreviewOpen(false)}
          isOpen={true}
          className={s['bb-santa-letter__preview-modal']}
          overlayClassName={s['bb-santa-letter__preview-overlay']}
          closeButton={previewCloseButtonElement}
        >
          <div className={s['bb-santa-letter__letter-preview-wrapper']} ref={letterPreviewWrapper}>
            <TransformWrapper>
              <TransformComponent>
                <div
                  className={s['bb-santa-letter__letter-preview']}
                  style={{
                    transform: `scale(${previewScaleFactor})`,
                  }}
                >
                  <SantaLetterTemplate
                    name={formState.name}
                    present={formState.present}
                    postScriptum={formState.post_scriptum}
                  />
                </div>
              </TransformComponent>
            </TransformWrapper>
          </div>
        </Popup>
      )}

      {isSubmitModalVisible && (
        <SantaLetterModal
          {...modal}
          isPopupOpened={isSubmitModalVisible}
          onClose={() => {
            // Reset form state.
            setFormState(formStateInitialValues);
            setSubmitModalVisible(false);
            setIsTouched(false);
          }}
        />
      )}
    </div>
  );
};

BBSantaLetter.propTypes = {
  title: PropTypes.string.isRequired,
  behaviorSettings: behaviorSettingsProps,
  productId: PropTypes.number.isRequired,
  uuid: PropTypes.string,
  modal: PropTypes.shape({
    link: PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape(linkPropTypes),
    }),
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default BBSantaLetter;
