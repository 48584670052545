import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import DefaultPicture, {
  propTypes as defaultPicturePropTypes,
} from '../../../../01_atoms/DefaultPicture';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';
import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import FormattedText from '../../../../01_atoms/FormattedText';
import BlockFooter from '../../../../02_molecules/BlockFooter';
import LogoWhite from '../../../../../public/static/icons/logos/logo-white.svg';

import styles from './index.module.scss';
import useScreenSize from '../../../../../hooks/useScreenSize';
import { pushViewItem as gtmPushViewItem } from '../../../../../utils/gtm';

const BBHeroSocialAd = ({
  logoLink,
  title,
  image,
  heading,
  buttonDescription = '',
  button,
  label = '',
  sectionContent,
  behaviorSettings = null,
  uuid = null,
  appealId = null,
  appealTitle = '',
  appealDefault = 'single',
  variant = 'default',
}) => {
  const { width } = useScreenSize();
  const [topSectionHeight, setTopSectionHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (appealId) {
      gtmPushViewItem([
        {
          item_id: appealId,
          item_name: appealTitle,
          item_category: 'Appeal',
          item_variant: appealDefault,
        },
      ]);
    }
  }, [uuid, appealId, appealTitle, appealDefault]);

  useLayoutEffect(() => {
    const handleTopSectionHeight = () => {
      if (topSectionHeight !== ref.current.clientHeight) {
        document.fonts.ready.then(() => {
          setTopSectionHeight(ref.current.clientHeight);
        });
      }
    };

    window.addEventListener('resize', handleTopSectionHeight);
    handleTopSectionHeight();

    return () => {
      window.removeEventListener('resize', handleTopSectionHeight);
    };
  }, []);

  const isDesktop = width >= 1200;
  const secondInfoMargin = !isDesktop || topSectionHeight > 542 ? 0 : 542 - (topSectionHeight + 40);

  const classes = [
    'bb',
    'bb-hero',
    styles['bb-hero-social-ad'],
    styles[`bb-hero-social-ad--variant-${variant}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div id={uuid} className={classes.join(' ')}>
      <div className={styles['bb-hero-social-ad__top-section']} ref={ref}>
        <div className="container">
          {logoLink && (
            <div className={styles['bb-hero-social-ad__site-logo']}>
              <Link {...logoLink}>
                <a tabIndex="0">
                  <LogoWhite
                    alt="Concern Worldwide"
                    className={styles['bb-hero-social-ad__logo-image']}
                    aria-label="Concern Worldwide"
                  />
                </a>
              </Link>
            </div>
          )}
          <div className={styles['bb-hero-social-ad__content']}>
            <h1 className={styles['bb-hero-social-ad__title']}>{title}</h1>
            <div className={styles['bb-hero-social-ad__image']}>
              <DefaultPicture {...image} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles['bb-hero-social-ad__bottom-section']}>
        <div className="container">
          <div className={styles['bb-hero-social-ad__image-placeholder']}></div>
          <div className={styles['bb-hero-social-ad__content']}>
            <div className={styles['bb-hero-social-ad__first-info']}>
              <FormattedText
                tag="h2"
                className={styles['bb-hero-social-ad__heading']}
                text={heading}
              />
              {isDesktop && button?.nextLink && (
                <BlockFooter
                  button={button}
                  variant={variant}
                  buttonDescription={buttonDescription}
                />
              )}
            </div>
            <div
              className={styles['bb-hero-social-ad__second-info']}
              style={{ marginTop: secondInfoMargin }}
            >
              {label && <div className={styles['bb-hero-social-ad__label']}>{label}</div>}
              {sectionContent.type === 'text' && (
                <FormattedText
                  className={styles['bb-hero-social-ad__highlighted-text']}
                  text={sectionContent.text}
                />
              )}
              {sectionContent.type === 'list' && (
                <ul
                  className={`${styles['bb-hero-social-ad__list']} ${
                    styles[`bb-hero-social-ad__list--${sectionContent.variant}`]
                  }`}
                >
                  {sectionContent.items.map(
                    (item) =>
                      item.title &&
                      item.text && (
                        <li className={styles['bb-hero-social-ad__list-item']} key={item.title}>
                          {item.title && (
                            <h3 className={styles['bb-hero-social-ad__list-item-title']}>
                              {item.title}
                            </h3>
                          )}
                          {item.text && (
                            <FormattedText
                              className={styles['bb-hero-social-ad__list-item-text']}
                              text={item.text}
                            />
                          )}
                        </li>
                      ),
                  )}
                </ul>
              )}
            </div>
            {!isDesktop && button?.nextLink && (
              <BlockFooter
                variant={variant}
                button={button}
                buttonDescription={buttonDescription}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

BBHeroSocialAd.propTypes = {
  logoLink: PropTypes.shape(linkPropTypes).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape(defaultPicturePropTypes).isRequired,
  heading: PropTypes.string.isRequired,
  buttonDescription: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }).isRequired,
  label: PropTypes.string,
  sectionContent: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(['text']),
      text: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      type: PropTypes.oneOf(['list']),
      variant: PropTypes.oneOf(['green', 'yellow']).isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
        }),
      ),
    }),
  ]).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
  appealId: PropTypes.number,
  appealTitle: PropTypes.string,
  appealDefault: PropTypes.oneOf(['single', 'monthly']),
  variant: PropTypes.oneOf(['default', 'emergency']),
};

export default BBHeroSocialAd;
